import React from "react"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"

const WhyEcosystems = () => {
    return (
        <Layout>
            <div className="bg-blue-500 pb-16">
                <div className="container mx-auto px-16 pt-20 pb-8 text-center">
                    <h1 className="text-5xl text-white font-normal">Why Ecosystem</h1>
                </div>
            </div>
            <div className="container mx-auto">
                <div className="flex justify-center pt-16">
                    <div className="w-1/2 mt-12">
                        <h2 className="text-center text-4xl text-blue-500 font-light">
                            Here are <b className="text-5xl">5 reasons</b> to join your
                            business or venture in our database:
                        </h2>
                    </div>
                </div>

                <div className="flex justify-center pt-12 pb-16">
                    <div className="w-1/2 mt-12">
                        <div className="flex justify-center items-center">
                            <div className="w-1/2 mx-6 ">
                                {/* <h1 className="text-3xl orangeText">Reason 1</h1> */}
                                <StaticImage
                                    src="../images/enterpreneurship.png"
                                    alt="Woman"
                                    className="w-full shadow-lg rounded-md mt-6"
                                />
                            </div>
                            <div className="w-1/2 mx-6">
                                <div className="flex justify-center mb-5">
                                    <p className="text-center bg-blue-500 rounded-full py-2 px-4 text-white">
                                        1
                                    </p>
                                </div>
                                <p>
                                    Enhance coordination, rapport and specialisation within the
                                    entrepreneurship domain for accurate assessments of impact.
                                </p>
                            </div>
                        </div>

                        <div className="flex justify-center items-center mt-16">
                            <div className="w-1/2 mx-6">
                                <div className="flex justify-center mb-5">
                                    <p className="text-center bg-blue-500 rounded-full py-2 px-4 text-white">
                                        2
                                    </p>
                                </div>
                                <p>
                                    Comprehend the gaps in private sector logistics as they exist
                                    and render much-needed relief to ease prevailing constraints.
                                </p>
                            </div>
                            <div className="w-1/2 mx-6">
                                {/* <h1 className="text-3xl orangeText text-right">Reason 2</h1> */}
                                <StaticImage
                                    src="../images/logistics.png"
                                    alt="Woman"
                                    className="w-full shadow-lg rounded-md mt-6"
                                />
                            </div>
                        </div>

                        <div className="flex justify-center items-center mt-16">
                            <div className="w-1/2 mx-6">
                                {/* <h1 className="text-3xl orangeText">Reason 3</h1> */}
                                <StaticImage
                                    src="../images/startups.png"
                                    alt="Woman"
                                    className="w-full shadow-lg rounded-md mt-6"
                                />
                            </div>
                            <div className="w-1/2 mx-6">
                                <div className="flex justify-center mb-5">
                                    <p className="text-center bg-blue-500 rounded-full py-2 px-4 text-white">
                                        3
                                    </p>
                                </div>
                                <p>
                                    Devise feasible plans designed to help startups and emerging
                                    entrepreneurs grow.
                                </p>
                            </div>
                        </div>

                        <div className="flex justify-center items-center mt-16">
                            <div className="w-1/2 mx-6">
                                <div className="flex justify-center mb-5">
                                    <p className="text-center bg-blue-500 rounded-full py-2 px-4 text-white">
                                        4
                                    </p>
                                </div>
                                <p>
                                    Develop and execute projects aiming to cultivate public
                                    interest and accelerate innovation for multi-sectoral
                                    development.
                                </p>
                            </div>
                            <div className="w-1/2 mx-6">
                                {/* <h1 className="text-3xl orangeText text-right">Reason 4</h1> */}
                                <StaticImage
                                    src="../images/ideation.png"
                                    alt="Woman"
                                    className="w-full shadow-lg rounded-md mt-6"
                                />
                            </div>
                        </div>

                        <div className="flex justify-center items-center my-16">
                            <div className="w-1/2 mx-6">
                                {/* <h1 className="text-3xl orangeText">Reason 5</h1> */}
                                <StaticImage
                                    src="../images/transformation.png"
                                    alt="Woman"
                                    className="w-full shadow-lg rounded-md mt-6"
                                />
                            </div>
                            <div className="w-1/2 mx-6">
                                <div className="flex justify-center mb-5">
                                    <p className="text-center bg-blue-500 rounded-full py-2 px-4 text-white">
                                        5
                                    </p>
                                </div>
                                <p>
                                    Carve and transform Sierra Leone into a rich, diverse and
                                    flourishing entrepreneurship centre
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </Layout>
    )
}

export default WhyEcosystems
